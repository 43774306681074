import { get, post, put } from './';

export const createBooking = payload => post('/api/v1/koob/bookings', payload);
export const getBookingStatus = ({ bookingId, lang, currency }) =>
  get(`/api/v1/koob/bookings/${bookingId}/status`, { lang, currency });
export const initializeBookingPayment = ({ bookingId }) =>
  post(`/api/v1/koob/bookings/${bookingId}/payment_initialize`);
export const confirmBookingPayment = ({ bookingId }) =>
  post(`/api/v1/koob/bookings/${bookingId}/payment_confirm`);
export const getBooking = ({ bookingId }) =>
  get(`/api/v1/koob/bookings/${bookingId}`);
export const getBookingCancelFees = ({ bookingId, lang, currency }) =>
  get(`/api/v1/koob/bookings/${bookingId}/fees`, { lang, currency });
export const cancelBooking = ({ bookingId, lang, currency }) =>
  post(`/api/v1/koob/bookings/${bookingId}/cancel`, { lang, currency });
export const calculatePromotionalCode = ({ bookingId, promotionalCode }) =>
  put(`/api/v1/koob/bookings/recalculate_booking`, {
    booking_id: bookingId,
    promotional_code: promotionalCode,
  });
