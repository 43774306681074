import React, { useContext } from 'react';
import styled from 'styled-components';
import { Link } from '@reach/router';
import moment from 'moment';
import { useAsync } from 'react-async';
import { get } from 'lodash';
import { getBooking } from 'api/bookings';
import {
  Paragraph as RawParagraph,
  Buttons as RawButtons,
  Button,
  ButtonLink as RawButtonLink,
  SpinnerContainer,
} from 'ui';
import { Container, TitleContainer } from 'ui/containers';
import { getItemAsArray } from 'utils';
import { journeyFormatDate } from 'utils/date';
import AuthContext from 'contexts/auth';
import Layout from 'components/Layout';
import Spinner from 'components/Spinner';
import PaymentDeclined from 'components/PaymentDeclined';
import { useAuthentication } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useDataLayerBuilder } from '@tymate/react-gtm';
import TicTacTrip from 'components/TicTacTrip';

const Buttons = styled(RawButtons)`
  margin-top: ${({ theme }) => theme.spacing(2)};
  > * {
    margin-right: ${({ theme }) => theme.spacing()};
  }
`;

const BookingReference = styled.div`
  font-weight: 700;
  margin-bottom: ${({ theme }) => theme.spacing()};
`;

const SummaryTitle = styled.div`
  font-weight: 700;
`;

const Picture = styled.img`
  margin-top: ${({ theme }) => theme.spacing()};
  margin-bottom: ${({ theme }) => theme.spacing()};
`;

const ShortDescriptionHotel = styled.span`
  font-size: 14px;
`;

const ButtonLink = styled(RawButtonLink)`
  color: ${({ theme }) => theme.primaryLight};
  font-size: 16px;
  text-transform: none;
  font-weight: 600;

  &:hover {
    color: ${({ theme }) => theme.primary};
  }
`;

const Paragraph = styled(RawParagraph)`
  margin-top: ${({ theme }) => theme.spacing()};
  margin-bottom: ${({ theme }) => theme.spacing()};
`;

const formatKoediaDate = date => moment(date, 'YYYY-MM-DD').toDate();

const BookingDetails = ({ bookingId }) => {
  useAuthentication();
  const { t } = useTranslation();
  useDataLayerBuilder(() => ({ pageType: 'bookingDetails' }));

  const { user } = useContext(AuthContext);

  const { data: bookingData } = useAsync({
    promiseFn: getBooking,
    bookingId,
  });

  const booking = get(bookingData, 'booking');
  const bookingRef = get(booking, 'koobData.bookingRef');

  const bookingState = get(booking, 'state');

  const fromDate = get(booking, 'koobData.response.data.bookItem.roomsPlanBooked.@from');
  const toDate = get(booking, 'koobData.response.data.bookItem.roomsPlanBooked.@to');

  if (bookingState === 'failed_debit') {
    return <PaymentDeclined />;
  }

  if (bookingState !== 'paid' && bookingState !== 'registered_booking') {
    return (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
    );
  }

  const hotel = get(booking, 'koobData.response.data.bookItem', {});

  const hotelRooms = getItemAsArray(hotel.roomsPlanBooked.room);

  const amountPaid = (bookingData?.booking?.paidAmount / 100).toFixed(2);

  return (
      <Layout>
        <Container>
          {Boolean(user) && (
              <TitleContainer bold>{t('booking.details.title')}</TitleContainer>
          )}
          <Paragraph>
            <BookingReference>
              {t('booking.details.reference', {
                ref: bookingRef,
              })}
            </BookingReference>

            <div>{t('booking.details.heading')}</div>
            <div>
              {t('booking.details.text', {
                hotelName: get(booking, 'hotel.displayName'),
                address: hotel?.address,
                city: hotel?.city,
              })}
            </div>

            <Picture src={hotel.thumbnailUrl} alt="" />

            <div>
              <SummaryTitle>{t('booking.details.summary.title')}</SummaryTitle>
              {hotelRooms.map(({ roomLeader, roomDescription, roomPrice }) => (
                  <Paragraph>
                    <div>
                      {t('booking.details.summary.firstName', {
                        firstName: roomLeader.firstname,
                      })}
                    </div>
                    <div>
                      {t('booking.details.summary.lastName', {
                        lastName: roomLeader.lastname,
                      })}
                    </div>
                    <div>
                      {t('booking.details.summary.roomtype', {
                        roomType: roomDescription,
                      })}
                    </div>
                    <div>
                      {t('booking.details.summary.price', {
                        price: amountPaid,
                      })}
                    </div>
                  </Paragraph>
              ))}

              <div>
                {t('booking.details.summary.date', {
                  fromDate: journeyFormatDate(formatKoediaDate(fromDate)),
                  toDate: journeyFormatDate(formatKoediaDate(toDate)),
                })}
              </div>
            </div>
          </Paragraph>

          <Paragraph>
            Description :{' '}
            <ShortDescriptionHotel>
              {hotel.shortDescription}
            </ShortDescriptionHotel>
          </Paragraph>

          <Buttons isLeftAlign>
            <Button variant="primary" as={Link} to="/">
              {t('utils.home')}
            </Button>
            <Button variant="outline" as={Link} to="/" style= {{width: '300px'}}>
              {t('hotel.offer.other')}
            </Button>
          </Buttons>
          <Buttons isRightAlign>
            <ButtonLink to={`/booking-details/${bookingId}/cancel`}>
              {t('profiles.booking.cancelBooking')}
            </ButtonLink>
          </Buttons>
          <TicTacTrip/>
        </Container>
      </Layout>
  );
};

export default BookingDetails;
