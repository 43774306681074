import React from 'react';
import styled from 'styled-components';
import { navigate } from '@reach/router';
import { MdErrorOutline } from 'react-icons/md';
import { Stack, Button } from '@tymate/margaret';
import Layout from 'components/Layout';
import { Container } from 'ui/containers';

const Wrapper = styled.div`
  display: grid;
  place-content: center;
  height: calc(50vh - 3px);
`;

const Card = styled(Stack).attrs({
    direction: 'column',
    alignX: 'center',
    gutterSize: 1,
})`
  color: ${({ theme }) => theme.error};
  background-color: ${({ theme }) => theme.errorLighter};
  border: 1px solid ${({ theme }) => theme.errorLight};
  padding: ${({ theme }) => theme.spacing(3)};
  border-radius: 8px;
  min-width: 500px;
  max-width: 800px;
`;

const CardSubtitle = styled.h2`
  display: block;
  color: ${({ theme }) => theme.text};
`;

const CardContent = styled.div`
  display: block;
  color: ${({ theme }) => theme.text};
  text-align: center;
`;

const HomeButton = styled(Button).attrs({ variant: 'primary' })`
  margin-top: ${({ theme }) => theme.spacing(3)};
`;

const ProfilDetails = () => {
    return (
        <Layout>
            <Container>
                <Wrapper>
                    <Card>
                        <MdErrorOutline size={42} />
                        <CardSubtitle>
                            Nous sommes désolés, le paiement a échoué !
                        </CardSubtitle>
                        <CardContent>
                            Malheureusement, Feelingo n'a pas pu valider votre réservation.
                            Veuillez vous assurer que les informations renseignées sont
                            correctes et/ou que votre solde bancaire permet la transaction. Si
                            ce n'est pas le cas, vous pouvez essayer un autre moyen de
                            paiement.
                        </CardContent>
                        <HomeButton
                            onClick={() => {
                                navigate('/');
                            }}
                        >
                            Retour à l'accueil
                        </HomeButton>
                    </Card>
                </Wrapper>
            </Container>
        </Layout>
    );
};

export default ProfilDetails;
