import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAsync } from 'react-async';
import { get } from 'lodash';
import { Link } from 'gatsby';
import Layout from 'components/Layout';
import { Paragraph, Buttons, Button, SpinnerContainer } from 'ui';
import { Container, TitleContainer } from 'ui/containers';
import { getBooking, getBookingCancelFees, cancelBooking } from 'api/bookings';
import Spinner from 'components/Spinner';

const fetchCancelFees = ({ bookingRef }) => {
  if (!bookingRef) {
    return;
  }
  return getBookingCancelFees({
    bookingId: bookingRef,
    lang: 'fre',
    currency: 'EUR',
  });
};

const BookingCancel = ({ bookingId }) => {
  const { t } = useTranslation();
  const [cancelled, setCancelled] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { data: bookingData } = useAsync({
    promiseFn: getBooking,
    bookingId,
  });

  const bookingRef = get(bookingData, 'booking.koobData.bookingRef');

  const { data } = useAsync({
    promiseFn: fetchCancelFees,
    bookingRef,
    watch: bookingRef,
  });

  const cancelFees = get(data, 'data.cancelFees.@rack');

  const handleCancel = async e => {
    setLoading(true);
    try {
      await cancelBooking({
        bookingId: get(bookingData, 'booking.koobData.id'),
        lang: 'fre',
        currency: 'EUR',
      });
    } catch {}
    setLoading(false);
    setCancelled(true);
  };

  if (isLoading) {
    return (
      <SpinnerContainer>
        <Spinner />
        <p style={{ textAlign: 'center', fontSize: 22 }}>
          {t('booking.cancellation.cancelLoad')}
        </p>
      </SpinnerContainer>
    );
  }

  return (
    <Layout>
      <Container>
        <TitleContainer>{t('booking.cancellation.title')}</TitleContainer>
        {!cancelled ? (
          <>
            {cancelFees && (
              <Paragraph>
                {`${t('profiles.booking.cancelFees')} ${cancelFees} €`}
              </Paragraph>
            )}
            <Buttons isLeftAlign>
              <Button
                variant="danger"
                onClick={handleCancel}
                disabled={!cancelFees}
              >
                {t('profiles.booking.cancelBooking')}
              </Button>
            </Buttons>
          </>
        ) : isLoading ? (
          <SpinnerContainer>
            <Spinner />
            <Paragraph>{t('booking.cancellation.cancelLoad')}</Paragraph>
          </SpinnerContainer>
        ) : (
          <>
            <Paragraph>{t('profiles.booking.resaCancel')}</Paragraph>
            <Buttons>
              <Button variant="primary" as={Link} to="/">
                {t('utils.home')}
              </Button>
            </Buttons>
          </>
        )}
      </Container>
    </Layout>
  );
};

export default BookingCancel;
