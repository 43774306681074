import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

const Section = styled.div`
  padding-top: ${({ theme }) => theme.spacing(2)};
  ${props =>
    props.centered &&
    css`
      text-align: center;
    `}

    p {
        ${({ theme }) => theme.fontStyles.bodyLarger};
    }
    
  ${props =>
  props.bordered &&
  css`
      border: 1px solid rgba(0, 0, 0, 0.12);
      background-color: #fff;
      border-radius: 30px;
      padding:10px;
  `}
    
    
`;
const TicTacTrip = ({hideTitle,notCentered, bordered}) => {
  const { t } = useTranslation();
  return (
    <Section centered={!notCentered} bordered={bordered}>
      {!hideTitle && (<p style={{ maxWidth: '1000px' , margin: 'auto', fontWeight: 'bold', fontSize:22 }}>{t('home.ticTacTrip.titleBeforFrame')} </p>)}
      <p style={{ maxWidth: '1000px' , margin: 'auto' }}>
          <iframe
              title="tictactrip"
              src="https://www.tictactrip.eu/?partnerId=WB-OTA-FEELINGO&utm_source=widget_booking&utm_medium=referral&utm_campaign=WB-OTA-FEELINGO&cher"
              width="100%"
              height="1139px"
              frameBorder="0"
          />
      </p>
    </Section>
  );
};

export default TicTacTrip;
