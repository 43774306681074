import React from 'react';
import { Router } from '@reach/router';
import BookingDetails from './BookingDetails';
import BookingCancel from './BookingCancel';
import { useDataLayerBuilder } from '@tymate/react-gtm';

const Page = () => {
  useDataLayerBuilder(() => ({
    pageType: 'bookingDetails',
  }));

  return (
    <Router>
      <BookingDetails path="/booking-details/:bookingId" />
      <BookingCancel path="/booking-details/:bookingId/cancel" />
    </Router>
  );
};

export default Page;
